import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../firebase';
import './MicrosoftAzureConfiguration.css';
import Modal from './Modal';

const CONTROL_MAPPINGS = {
  C045: {
    controlName: 'Mobile Application Management',
    permissions: ['DeviceManagementApps.Read.All'],
    evidenceType: 'apps',
    description: 'List of managed apps, app assignment policies'
  },
  C046: {
    controlName: 'Device Configuration Management',
    permissions: ['DeviceManagementConfiguration.Read.All'],
    evidenceType: 'configurations',
    description: 'Device configuration profiles, settings enforced'
  },
  C047: {
    controlName: 'Device Access Control',
    permissions: ['DeviceManagementManagedDevices.Read.All', 'DeviceManagementServiceConfig.Read.All'],
    evidenceType: ['devices', 'compliance'],
    description: 'Device enrollment status, compliance status, access restrictions'
  },
  C048: {
    controlName: 'Intune Administrator Access Control',
    permissions: ['DeviceManagementRBAC.Read.All'],
    evidenceType: 'roles',
    description: 'Intune role definitions and assignments'
  },
  C049: {
    controlName: 'Device Compliance and Security Baselines',
    permissions: ['DeviceManagementServiceConfig.Read.All'],
    evidenceType: 'compliance',
    description: 'Compliance policies and security baselines'
  },
  C050: {
    controlName: 'Device Inventory Management',
    permissions: ['DeviceManagementManagedDevices.Read.All'],
    evidenceType: 'devices',
    description: 'List of enrolled devices and details'
  }
};

const FullScreenEvidence = ({ data, type, onClose, onBack }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 50;

  // Ensure we have valid data
  const records = data?.sample || [];

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
  };

  // Filter records based on search
  const filteredRecords = records.filter(record => 
    Object.values(record).some(value => 
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Sort records
  const sortedRecords = [...filteredRecords].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aVal = a[sortConfig.key];
    const bVal = b[sortConfig.key];
    if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  // Pagination
  const totalPages = Math.ceil(sortedRecords.length / recordsPerPage);
  const paginatedRecords = sortedRecords.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  if (!data || !records.length) {
    return (
      <div className="full-screen-evidence">
        <div className="evidence-header">
          <div className="header-left">
            <button onClick={onBack} className="back-button">
              ← Back to Evidence Types
            </button>
            <h2>{type.charAt(0).toUpperCase() + type.slice(1)} Evidence</h2>
          </div>
          <button onClick={onClose} className="close-button">Close</button>
        </div>
        <div className="no-data-message">
          No evidence data available.
        </div>
      </div>
    );
  }

  return (
    <div className="full-screen-evidence">
      <div className="evidence-header">
        <div className="header-left">
          <button onClick={onBack} className="back-button">
            ← Back to Evidence Types
          </button>
          <h2>{type.charAt(0).toUpperCase() + type.slice(1)} Evidence</h2>
        </div>
        <div className="evidence-controls">
          <input
            type="text"
            placeholder="Search records..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <button onClick={onClose} className="close-button">Close</button>
        </div>
      </div>

      <div className="evidence-table-container">
        <table>
          <thead>
            <tr>
              {Object.keys(records[0] || {}).map(key => (
                <th 
                  key={key}
                  onClick={() => handleSort(key)}
                  className={sortConfig.key === key ? `sorted-${sortConfig.direction}` : ''}
                >
                  {key}
                  {sortConfig.key === key && (
                    <span className="sort-indicator">
                      {sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedRecords.map((record, idx) => (
              <tr key={idx}>
                {Object.values(record).map((value, vidx) => (
                  <td key={vidx}>
                    {typeof value === 'object' ? JSON.stringify(value) : value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="evidence-footer">
        <div className="pagination-controls">
          <button 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        <div className="evidence-stats">
          <p>Showing {paginatedRecords.length} of {filteredRecords.length} records</p>
          <p>Total Records: {data.totalCount}</p>
          <p>Last Updated: {new Date(data.timestamp).toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};

const MicrosoftAzureConfiguration = ({ onClose, onSave, now_viewing_org_id }) => {
  console.log('MicrosoftAzureConfiguration now_viewing_org_id:', now_viewing_org_id);

  const [config, setConfig] = useState({
    integration_enabled: false,
    test_status: 'awaiting',
    script_url: 'https://azure-validator-qravzvgjba-uc.a.run.app',
    values: [
      { field_name: 'clientId', field_value: '' },
      { field_name: 'clientSecret', field_value: '' },
      { field_name: 'tenantId', field_value: '' }
    ],
    permissions: [
      { enabled: true, slug: 'DeviceManagementApps.Read.All' },
      { enabled: true, slug: 'DeviceManagementConfiguration.Read.All' },
      { enabled: true, slug: 'DeviceManagementManagedDevices.Read.All' },
      { enabled: true, slug: 'DeviceManagementRBAC.Read.All' },
      { enabled: true, slug: 'DeviceManagementServiceConfig.Read.All' }
    ],
    tool_slug: 'microsoft_azure_graph_api'
  });
  const [testing, setTesting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [copiedPermission, setCopiedPermission] = useState(null);
  const [copiedAll, setCopiedAll] = useState(false);
  const [testResults, setTestResults] = useState({
    overall: null,
    permissions: {}
  });
  const [testingPermission, setTestingPermission] = useState(null);
  const [testResponse, setTestResponse] = useState(null);
  const [showRawResponse, setShowRawResponse] = useState(false);
  const [evidence, setEvidence] = useState(null);
  const [selectedControl, setSelectedControl] = useState(null);
  const [evidenceView, setEvidenceView] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState(null);

  useEffect(() => {
    let unsubscribe = () => {};

    const setupConfigListener = async () => {
      if (!now_viewing_org_id) {
        setLoading(false);
        return;
      }

      const toolRef = doc(db, 'tools', `${now_viewing_org_id}_microsoft_azure_graph_api`);
      
      unsubscribe = onSnapshot(toolRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setConfig(prev => ({
            ...prev,
            ...data,
            permissions: data.permissions?.length ? data.permissions : prev.permissions
          }));
        }
        setLoading(false);
      }, (error) => {
        console.error('Error listening to config:', error);
        setLoading(false);
      });
    };

    setupConfigListener();

    return () => unsubscribe();
  }, [now_viewing_org_id]);

  useEffect(() => {
    if (config.test_status === 'awaiting') {
      setConfig(prev => ({
        ...prev,
        integration_enabled: false
      }));
    }
  }, [config.test_status]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setConfig(prev => ({
      ...prev,
      values: prev.values.map(field => 
        field.field_name === name 
          ? { ...field, field_value: value }
          : field
      )
    }));
  };

  const validateInputs = () => {
    const requiredFields = ['clientId', 'clientSecret', 'tenantId'];
    const missingFields = requiredFields.filter(field => 
      !config.values.find(f => f.field_name === field)?.field_value
    );
    
    if (missingFields.length > 0) {
      alert(`Please fill in all required fields: ${missingFields.join(', ')}`);
      return false;
    }
    return true;
  };

  const handleSaveClick = async () => {
    if (!validateInputs()) return;

    if (!now_viewing_org_id) {
      console.error('Cannot save: now_viewing_org_id is missing');
      alert('Error: Organization ID is missing');
      return;
    }

    try {
      setLoading(true);
      const toolRef = doc(db, 'tools', `${now_viewing_org_id}_microsoft_azure_graph_api`);
      
      const toolData = {
        ...config,
        script_url: 'https://azure-validator-qravzvgjba-uc.a.run.app',
        updated_at: new Date().toISOString(),
        updated_by_uid: auth.currentUser?.uid || '',
        orgId: now_viewing_org_id,
        tool_slug: 'microsoft_azure_graph_api'
      };

      await setDoc(toolRef, toolData);
      onSave(toolData);
      alert('Configuration saved successfully!');
    } catch (error) {
      console.error('Error saving configuration:', error);
      alert(`Failed to save configuration: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const testPermissions = async (permissions) => {
    try {
      const enabledPermissions = config.permissions
        .filter(p => p.enabled)
        .map(p => p.slug);

      if (enabledPermissions.length === 0) {
        throw new Error('Please enable at least one permission to test');
      }

      const clientId = config.values.find(f => f.field_name === 'clientId')?.field_value;
      const clientSecret = config.values.find(f => f.field_name === 'clientSecret')?.field_value;
      const tenantId = config.values.find(f => f.field_name === 'tenantId')?.field_value;

      console.log('Testing permissions with:', {
        url: config.script_url + '/validate',
        permissions: enabledPermissions,
        clientIdLength: clientId?.length,
        clientSecretLength: clientSecret?.length,
        tenantIdLength: tenantId?.length
      });

      // First check if the validator service is reachable
      try {
        const response = await fetch(config.script_url + '/validate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            clientId,
            clientSecret,
            tenantId,
            permissions: enabledPermissions
          })
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          
          try {
            const errorData = JSON.parse(errorText);
            throw new Error(errorData.message || 'Validation service error');
          } catch (parseError) {
            throw new Error(`Server returned ${response.status}: ${errorText}`);
          }
        }

        const data = await response.json();
        console.log('Validation response:', data);
        return data;

      } catch (fetchError) {
        console.error('Fetch error:', fetchError);
        if (fetchError.message.includes('Failed to fetch')) {
          throw new Error(`Cannot reach validation service at ${config.script_url}. Please check your network connection or try again later.`);
        }
        throw fetchError;
      }

    } catch (error) {
      console.error('Error in testPermissions:', error);
      throw error;
    }
  };

  const handleTest = async () => {
    if (!validateInputs()) return;

    setTesting(true);
    setTestResults({ overall: null, permissions: {} });
    setTestResponse(null);
    setEvidence(null);

    try {
      const permissionSlugs = config.permissions
        .filter(p => p.enabled)
        .map(p => p.slug);
      
      console.log('Starting permission test for:', permissionSlugs);
      
      const response = await testPermissions(permissionSlugs);
      console.log('Test completed:', response);
      
      setTestResponse(response);
      setTestResults({
        overall: response.success ? 'success' : 'failed',
        permissions: response.results
      });

      if (response.evidence) {
        setEvidence(response.evidence);
      }

      if (response.success) {
        const toolRef = doc(db, 'tools', `${now_viewing_org_id}_microsoft_azure_graph_api`);
        
        const sanitizedEvidence = response.evidence ? Object.entries(response.evidence).reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = {
              totalCount: value.totalCount || 0,
              sampleCount: value.sample?.length || 0,
              timestamp: value.timestamp || new Date().toISOString(),
              sampleIds: value.sample?.map(item => item.id || item.deviceId || item.displayName || 'unknown').slice(0, 3) || []
            };
          }
          return acc;
        }, {}) : null;

        await setDoc(toolRef, {
          ...config,
          test_status: 'success',
          evidence: sanitizedEvidence,
          last_successful_test: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          updated_by_uid: auth.currentUser?.uid || ''
        }, { merge: true });
      }

      setConfig(prev => ({
        ...prev,
        test_status: response.success ? 'success' : 'awaiting'
      }));

      if (!response.success) {
        const failedPermissions = Object.entries(response.results)
          .filter(([_, result]) => !result.success)
          .map(([perm, result]) => `${perm}:\n${result.error}`)
          .join('\n\n');
        
        alert(`Some permissions failed:\n\n${failedPermissions}`);
      }
    } catch (error) {
      console.error('Test error:', error);
      setTestResults({
        overall: 'error',
        permissions: {}
      });
      setConfig(prev => ({
        ...prev,
        test_status: 'awaiting'
      }));
      alert(`Error testing configuration: ${error.message}`);
    } finally {
      setTesting(false);
      setTestingPermission(null);
    }
  };

  const copyPermissionToClipboard = (permission) => {
    navigator.clipboard.writeText(permission);
    setCopiedPermission(permission);
    setTimeout(() => setCopiedPermission(null), 1500);
  };

  const copyPermissionsToClipboard = () => {
    const permissionsText = availablePermissions.join('\n');
    navigator.clipboard.writeText(permissionsText);
    setCopiedAll(true);
    setTimeout(() => setCopiedAll(false), 1500);
  };

  const handleToggleEnabled = () => {
    setConfig(prev => ({
      ...prev,
      integration_enabled: !prev.integration_enabled
    }));
  };

  const availablePermissions = [
    'DeviceManagementApps.Read.All',
    'DeviceManagementConfiguration.Read.All',
    'DeviceManagementManagedDevices.Read.All',
    'DeviceManagementRBAC.Read.All',
    'DeviceManagementServiceConfig.Read.All'
  ];

  const handleTogglePermission = (permissionSlug) => {
    setConfig(prev => ({
      ...prev,
      permissions: prev.permissions.map(p => 
        p.slug === permissionSlug ? { ...p, enabled: !p.enabled } : p
      )
    }));
  };

  const renderPermissionsList = () => (
    <div className="azure-permissions-list">
      {config.permissions.map(permission => (
        <div key={permission.slug} className="permission-item">
          <label className="permission-toggle">
            <input
              type="checkbox"
              checked={permission.enabled}
              onChange={() => handleTogglePermission(permission.slug)}
            />
            <span className="permission-name">{permission.slug}</span>
          </label>
        </div>
      ))}
    </div>
  );

  const renderTestResults = () => {
    if (!testResults.overall) return null;

    return (
      <div className="azure-test-results">
        <h4>Test Results</h4>
        
        <div className="test-results-summary">
          <div className={`test-status ${testResults.overall}`}>
            Overall Status: {testResults.overall}
          </div>
        </div>

        <div className="test-results-permissions">
          {Object.entries(testResults.permissions).map(([permission, result]) => (
            <div key={permission} className={`test-result-item ${result.success ? 'success' : 'failed'}`}>
              <span className="test-status-icon">{result.success ? '✓' : '✗'}</span>
              <div className="test-result-content">
                <span className="test-permission">{permission}</span>
                {!result.success && result.error && (
                  <div className="test-error-message">{result.error}</div>
                )}
              </div>
            </div>
          ))}
        </div>

        {evidence && (
          <div className="azure-evidence">
            <h4>Configuration Evidence</h4>
            {Object.entries(evidence).map(([type, data]) => (
              <div key={type} className="evidence-section">
                <h5>{type.charAt(0).toUpperCase() + type.slice(1)}</h5>
                <div className="evidence-details">
                  <p>Total Count: {data.totalCount}</p>
                  {data.sample && data.sample.length > 0 && (
                    <div className="evidence-sample">
                      <p>Sample Items:</p>
                      <div className="sample-items">
                        {data.sample.map((item, index) => (
                          <div key={index} className="sample-item">
                            <h6>{item.displayName || item.id || 'Unnamed Item'}</h6>
                            {item.description && (
                              <p className="item-description">{item.description}</p>
                            )}
                            {item.publisher && (
                              <p className="item-publisher">Publisher: {item.publisher}</p>
                            )}
                            {item.createdDateTime && (
                              <p className="item-date">Created: {new Date(item.createdDateTime).toLocaleDateString()}</p>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <p className="evidence-timestamp">
                    Collected at: {new Date(data.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="test-raw-response">
          <button 
            className="accordion-toggle"
            onClick={() => setShowRawResponse(!showRawResponse)}
          >
            {showRawResponse ? '▼' : '▶'} Raw Response
          </button>
          {showRawResponse && (
            <pre className="raw-response-content">
              {JSON.stringify(testResponse, null, 2)}
            </pre>
          )}
        </div>
      </div>
    );
  };

  const renderControlsTable = () => (
    <div className="controls-table">
      <h3>Control Mappings</h3>
      <table>
        <thead>
          <tr>
            <th>Control ID</th>
            <th>Control Name</th>
            <th>Permissions</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(CONTROL_MAPPINGS).map(([controlId, control]) => (
            <tr key={controlId}>
              <td>{controlId}</td>
              <td>{control.controlName}</td>
              <td>
                <ul>
                  {control.permissions.map(perm => (
                    <li key={perm}>{perm}</li>
                  ))}
                </ul>
              </td>
              <td>{control.description}</td>
              <td>
                <button
                  onClick={() => {
                    setSelectedControl(controlId);
                    setEvidenceView(true);
                  }}
                  disabled={!evidence || Object.keys(evidence).length === 0}
                >
                  View Evidence
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderEvidence = () => {
    if (!evidence) return null;

    return (
      <div className="evidence-viewer">
        {Object.entries(evidence).map(([category, categoryData]) => (
          <div key={category} className="evidence-category">
            {Object.entries(categoryData).map(([type, evidenceData]) => {
              // Use the full records array instead of the sample
              const records = evidenceData.records || evidenceData.value || evidenceData.sample;
              
              return (
                <div key={type} className="evidence-section">
                  <div className="evidence-header">
                    <h4>{type.charAt(0).toUpperCase() + type.slice(1)} Evidence</h4>
                    <div className="evidence-stats">
                      <span>Total Records: {evidenceData.totalCount}</span>
                      <span>Last Updated: {new Date(evidenceData.timestamp).toLocaleString()}</span>
                    </div>
                  </div>
                  
                  <div className="evidence-table-wrapper">
                    <table className="evidence-table">
                      <thead>
                        <tr>
                          {Object.keys(records[0] || {}).map(key => (
                            <th key={key}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((item, idx) => (
                          <tr key={idx}>
                            {Object.values(item).map((value, vidx) => (
                              <td key={vidx}>
                                {typeof value === 'object' ? JSON.stringify(value) : value}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="azure-config">
      {loading ? (
        <div className="azure-loading">Loading configuration...</div>
      ) : (
        <>
          <div className="azure-config-header">
            <button 
              className="azure-back-button" 
              onClick={onClose}
              aria-label="Go back"
            >
              ← Back
            </button>
            <h2>Configure Microsoft Azure Integration</h2>
            <button 
              className="azure-close-button" 
              onClick={onClose}
              aria-label="Close configuration"
            >
              ×
            </button>
          </div>

          <div className="azure-config-form">
            <div className="azure-config-section">
              <h3>Azure Application Details</h3>
              {config.values.map(field => (
                <div key={field.field_name} className="azure-form-group">
                  <label htmlFor={field.field_name} className="azure-form-label">
                    {field.field_name === 'clientId' ? 'Application (Client) ID' :
                     field.field_name === 'clientSecret' ? 'Client Secret' :
                     field.field_name === 'tenantId' ? 'Directory (Tenant) ID' : 
                     field.field_name}
                  </label>
                  <input
                    type={field.field_name === 'clientSecret' ? 'password' : 'text'}
                    id={field.field_name}
                    name={field.field_name}
                    value={field.field_value}
                    onChange={handleInputChange}
                    placeholder={`Enter your ${field.field_name}`}
                    className="azure-form-input"
                    aria-required="true"
                  />
                </div>
              ))}
            </div>

            <div className="azure-config-section">
              <div className="azure-permissions-header">
                <h3>Required Permissions</h3>
                <button 
                  type="button" 
                  className="azure-copy-button"
                  onClick={copyPermissionsToClipboard}
                >
                  {copiedAll ? 'Copied!' : 'Copy All'}
                </button>
              </div>
              <div className="azure-permissions-container">
                {renderPermissionsList()}
                {renderTestResults()}
              </div>
            </div>

            {!evidenceView ? renderControlsTable() : renderEvidence()}

            <div className="azure-form-actions">
              <div className="azure-buttons-row">
                <button 
                  type="button" 
                  className="azure-cancel-button" 
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button 
                  type="button" 
                  className="azure-test-button"
                  onClick={handleTest}
                  disabled={testing || !config.values.find(f => f.field_name === 'clientId')?.field_value || 
                             !config.values.find(f => f.field_name === 'clientSecret')?.field_value || 
                             !config.values.find(f => f.field_name === 'tenantId')?.field_value}
                >
                  {testing ? 'Testing...' : 'Test Configuration'}
                </button>
              </div>
              
              <div className="azure-toggle-container">
                <label className={`azure-toggle-label ${config.test_status === 'awaiting' ? 'disabled' : ''}`}>
                  Integration Status
                  <div className="azure-toggle-wrapper">
                    <div className="azure-toggle" title={config.test_status === 'awaiting' ? 'Please test connection to unlock enable' : ''}>
                      <input
                        type="checkbox"
                        checked={config.integration_enabled}
                        onChange={handleToggleEnabled}
                        disabled={config.test_status === 'awaiting'}
                      />
                      <span className="azure-toggle-slider"></span>
                    </div>
                    {config.test_status === 'awaiting' && (
                      <div className="azure-toggle-warning">
                        ⚠ Please test successfully to unlock enable
                      </div>
                    )}
                  </div>
                  {config.integration_enabled ? 'Enabled' : 'Disabled'}
                </label>
              </div>

              <button 
                type="button" 
                className="azure-save-button"
                onClick={handleSaveClick}
                disabled={testing}
              >
                Save Configuration
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MicrosoftAzureConfiguration;