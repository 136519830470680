import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LeftSidebar from './LeftSidebar';
import CenterContent from './CenterContent';
import RightSidebar from './RightSidebar';
import SuperSearch from './SuperSearch';
import './Dashboard.css'; 
import { useGlobalState } from '../context/GlobalStateContext';
import { getAuth } from 'firebase/auth';
import { db, isUserAuthenticated, getActiveUser } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import PersonnelComponent from './PersonnelComponent';

const Dashboard = ({ user }) => {
  const navigate = useNavigate();
  const [view, setView] = useState('overview'); 
  const [selectedControl, setSelectedControl] = useState(null); 
  const [selectedClient, setSelectedClient] = useState(null); 
  const [showClientSelectionPopup, setShowClientSelectionPopup] = useState(false);
  const [availableClients, setAvailableClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState(null);

  const { isAuditor, orgId, clientId, setClientId, setOrgName, selectedClientName, setSelectedClientName } = useGlobalState();

  // Initial auth check
  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = isUserAuthenticated();
      if (!authenticated) {
        navigate('/login', { replace: true });
        return;
      }
    };
    checkAuth();
  }, [navigate]);

  // Data fetching effect
  useEffect(() => {
    const fetchData = async () => {
      if (!isUserAuthenticated()) return;

      try {
        const activeUser = getActiveUser();
        if (!activeUser) return;

        // Force token refresh to ensure fresh auth state
        await activeUser.getIdToken(true);
        const tokenResult = await activeUser.getIdTokenResult();
        const now = Date.now() / 1000;
        
        if (!tokenResult.expirationTime || now >= tokenResult.claims.exp) {
          navigate('/login', { replace: true });
          return;
        }

        // If user is auditor and no client is selected, fetch clients
        if (isAuditor && !selectedClient && activeUser.email) {
          const userDoc = await getDocs(query(collection(db, 'users'), where('email', '==', activeUser.email)));
          if (!userDoc.empty) {
            const userData = userDoc.docs[0].data();
            const linkedClientsIds = userData.linkedClients || [];
            
            const clientsPromises = linkedClientsIds.map(clientId => 
              getDocs(query(collection(db, 'orgs'), where('id', '==', clientId)))
            );
            const clientsSnapshots = await Promise.all(clientsPromises);
            
            const clients = clientsSnapshots.flatMap(snapshot => 
              snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            );
            
            setAvailableClients(clients);
            setShowClientSelectionPopup(true);
          }
        }

        // Fetch user document
        const userDocRef = doc(db, 'users', activeUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserEmail(userDocSnap.data().email);
        } else {
          setUserEmail(activeUser.email);
        }

        // Fetch organization name and set it in global state
        if (orgId) {
          const orgDocRef = doc(db, 'organizations', orgId);
          const orgDocSnap = await getDoc(orgDocRef);
          if (orgDocSnap.exists()) {
            const orgName = orgDocSnap.data().name;
            setOrgName(orgName);
            console.log('Setting orgName in Dashboard:', orgName);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error in data fetching:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate, isAuditor, selectedClient, orgId, setOrgName]);

  const handleClientSelection = (client) => {
    setClientId(client.id);
    setSelectedClient(client.id);
    setSelectedClientName(client.name);
    setShowClientSelectionPopup(false);
  };

  const handleTileClick = (controlType, clientName, clientId) => {
    console.log('Clicked Tile:', { controlType, clientName, clientId });
    setSelectedControl(controlType);
    if (clientId) {
      console.log('Updating selectedClient state to:', clientId);
      setSelectedClient(clientId);
    }
    setView('controls');
  };

  const handleOpenDetailView = () => {
    console.log('handleOpenDetailView called!'); 
    setView('detail');
  };

  const handleCloseDetailView = () => {
    setView('controls');
  };  

  const handleControlsClick = async () => {
    if (clientId) {
      console.log('Updating selectedClient state to:', clientId);
      setSelectedClient(clientId);
    } else if (orgId) {
      console.log('Updating selectedOrg state to:', orgId);
      setSelectedClient(orgId);
    } else {
      console.error('No clientId or orgId available to fetch controls');
      return;
    }
  
    setView('controls');
  }; 

  if (loading) {
    return (
      <div className="dashboard loading">
        <div>Loading...</div>
      </div>
    );
  }

  if (!isUserAuthenticated()) {
    return null;
  }

  return (
    <div className="dashboard">
      {isUserAuthenticated() && <SuperSearch clientId={clientId} />}
      <LeftSidebar 
        onHomeClick={() => setView('overview')} 
        onDocumentsClick={() => setView('documents')} 
        onControlsClick={handleControlsClick} 
        onToDoListClick={() => setView('todolist')} 
        onVirtualTeamClick={() => setView('virtualteam')}
        onSettingsClick={() => setView('settings')}
        onControlLibraryClick={() => setView('controllibrary')} 
        onAccessReviewClick={() => setView('accessreview')}
        onIncidentReportClick={() => setView('incidentreport')}
        onSystemsHealthClick={() => setView('systemshealth')}
        onMobileDeviceManagementClick={() => setView('mobiledevicemanagement')}
        onEndpointDetectionAndResponseClick={() => setView('endpointdetectionandresponse')}
        onPatchingClick={() => setView('patching')}
        onAssetManagementClick={() => setView('assetmanagement')}
        onClientsClick={() => setView('clients')}
        onAuditorsClick={() => setView('auditors')}
        onVayuMissionControlClick={() => setView('vayumissioncontrol')}
        onScopingClick={() => setView('scoping')}
        onIssuesClick={() => setView('issues')}
        onRealTimeRiskProtectionClick={() => setView('live')}
        onIntegrationsClick={() => setView('integrations')}
        onDataWarehouseClick={() => setView('dataWarehouse')}
      />

      <CenterContent
        currentView={view}
        setView={setView}
        selectedControl={selectedControl}
        clientId={selectedClient}
        onTileClick={handleTileClick}
        onOpenDetailView={handleOpenDetailView} 
        onCloseDetailView={handleCloseDetailView} 
        userEmail={userEmail}
      />

      <RightSidebar onIssuesClick={() => setView('issues')} />

      {/* hiding the client selection popup for now, not necessary
      
      {showClientSelectionPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Select Client Tenant</h3>
            {availableClients.length > 0 ? (
              <div className="client-selection">
                {availableClients.map((client) => (
                  <div key={client.id}>
                    <button onClick={() => handleClientSelection(client)}>
                      {client.name}
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p>No linked clients available.</p>
            )}
            <button onClick={() => setShowClientSelectionPopup(false)}>Close</button>
          </div>
        </div>
      )}
        */}
    </div>
  );
};

export default Dashboard;
