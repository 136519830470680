import React, { createContext, useContext, useState } from 'react';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [clientId, setClientId] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [nowViewingOrgId, setNowViewingOrgId] = useState(null);
  const [availableOrgs, setAvailableOrgs] = useState([]); // For dropdown options
  const [orgName, setOrgName] = useState(null);
  const [selectedClientName, setSelectedClientName] = useState(null); // Add this line

  return (
    <GlobalStateContext.Provider value={{
      clientId,
      setClientId,
      orgId,
      setOrgId,
      nowViewingOrgId,
      setNowViewingOrgId,
      availableOrgs,
      setAvailableOrgs,
      orgName,
      setOrgName,
      selectedClientName,
      setSelectedClientName
    }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);