import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './IntegrationsView.css';
import MicrosoftAzureConfiguration from './MicrosoftAzureConfiguration';
import GoogleCloudConfiguration from './GoogleCloudConfiguration';

const IntegrationsView = ({ onClose, now_viewing_org_id }) => {
  console.log('IntegrationsView now_viewing_org_id:', now_viewing_org_id);

  const [searchQuery, setSearchQuery] = useState('');
  const [showAzureConfig, setShowAzureConfig] = useState(false);
  const [azureConfig, setAzureConfig] = useState(null);
  const [showGoogleConfig, setShowGoogleConfig] = useState(false);
  const [googleConfig, setGoogleConfig] = useState(null);

  useEffect(() => {
    const loadAzureConfig = async () => {
      try {
        const toolRef = doc(db, 'tools', `${now_viewing_org_id}_microsoft_azure_graph_api`);
        const toolSnap = await getDoc(toolRef);
        
        if (toolSnap.exists()) {
          setAzureConfig(toolSnap.data());
        }
      } catch (error) {
        console.error('Error loading Azure configuration:', error);
      }
    };

    if (now_viewing_org_id) {
      loadAzureConfig();
    }
  }, [now_viewing_org_id]);

  const integrations = [
    {
      id: 'azure',
      name: 'Microsoft Azure',
      description: 'Connect with Microsoft Graph API for enhanced security monitoring',
      icon: '🔷',
      category: 'ESSENTIALS',
      compatibleWith: ['Microsoft Intune'],
      status: azureConfig?.integration_enabled ? 'enabled' : 'available'
    },
    {
      id: 'google-cloud',
      name: 'Google Cloud Platform',
      description: 'Analyze service account permissions and security configurations',
      icon: '☁️',
      category: 'ESSENTIALS',
      compatibleWith: ['GCP IAM', 'Cloud Security'],
      status: googleConfig ? 'enabled' : 'available'
    }
  ];

  const handleAzureConnect = () => {
    setShowAzureConfig(true);
  };

  const handleSaveAzureConfig = (config) => {
    setAzureConfig(config);
    setShowAzureConfig(false);
  };

  const handleGoogleConnect = () => {
    setShowGoogleConfig(true);
  };

  const filteredIntegrations = integrations.filter(integration =>
    integration.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={`integrations-view ${showAzureConfig || showGoogleConfig ? 'showing-config' : ''}`}>
      {showAzureConfig ? (
        now_viewing_org_id ? (
          <MicrosoftAzureConfiguration
            onClose={() => setShowAzureConfig(false)}
            onSave={handleSaveAzureConfig}
            now_viewing_org_id={now_viewing_org_id}
          />
        ) : (
          <div>Error: Organization ID is missing</div>
        )
      ) : showGoogleConfig ? (
        <GoogleCloudConfiguration
          onClose={() => setShowGoogleConfig(false)}
          onSave={(config) => {
            setGoogleConfig(config);
            setShowGoogleConfig(false);
          }}
          now_viewing_org_id={now_viewing_org_id}
        />
      ) : (
        <>
          <div className="integrations-header">
            <h1>Integrations</h1>
            <p>Enhance your security experience with a wide variety of add-ons and integrations</p>
          </div>

          <div className="search-bar">
            <input
              type="text"
              placeholder="Search integrations"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="integrations-grid">
            {filteredIntegrations.map(integration => (
              <div key={integration.id} className="integration-card">
                <div className="integration-icon">{integration.icon}</div>
                <div className="integration-content">
                  <h3>{integration.name}</h3>
                  <p>{integration.description}</p>
                  {integration.compatibleWith && (
                    <div className="compatible-with">
                      <span className="compatibility-label">Compatible with:</span>
                      {integration.compatibleWith.map(tool => (
                        <span key={tool} className="compatibility-tag">{tool}</span>
                      ))}
                    </div>
                  )}
                </div>
                <button 
                  className={`connect-button ${integration.status === 'enabled' ? 'enabled' : ''}`} 
                  onClick={integration.id === 'azure' ? handleAzureConnect : handleGoogleConnect}
                >
                  {integration.status === 'enabled' ? 'Enabled' : 'Connect'}
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default IntegrationsView; 