import React, { useState, useEffect } from 'react';
import ComplianceTile from './ComplianceTile';
import './OverviewComponent.css';
import { auth } from '../firebase';
import { useGlobalState } from '../context/GlobalStateContext';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const OverviewComponent = ({ onTileClick }) => {
  const [complianceFrameworks, setComplianceFrameworks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuditor, setIsAuditor, setClientId, nowViewingOrgId, setSelectedClientName } = useGlobalState();

  useEffect(() => {
    const fetchComplianceFrameworks = async () => {
      setIsLoading(true);
      try {
        const currentUser = auth.currentUser;
        
        if (!currentUser || !nowViewingOrgId) {
          console.log('No authenticated user or viewing org ID - showing default view');
          setComplianceFrameworks([]);
          setIsLoading(false);
          return;
        }

        const orgDoc = await getDoc(doc(db, 'orgs', nowViewingOrgId));
        
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          console.log('Fetched org data:', orgData);

          if (orgData.complianceFramework && orgData.complianceFramework.length > 0) {
            const frameworks = orgData.complianceFramework.map(framework => ({
              framework,
              clientName: orgData.name,
              clientId: nowViewingOrgId,
              progress: 0,
              total: 0,
              isClickable: true
            }));
            setComplianceFrameworks(frameworks);
          } else {
            setComplianceFrameworks([{
              framework: "No frameworks configured",
              clientName: orgData.name,
              clientId: nowViewingOrgId,
              progress: 0,
              total: 0,
              isClickable: false
            }]);
          }
        }
      } catch (error) {
        console.error('Failed to fetch compliance frameworks:', error);
        setComplianceFrameworks([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComplianceFrameworks();
  }, [nowViewingOrgId]);

  return (
    <div className="overview-component">
      <h2>Overview</h2>
      {isLoading ? (
        <div className="cyberpunk-loader"><div className="cyberpunk-bar"></div></div>
      ) : (
        <div className="overview-slider">
          {!auth.currentUser ? (
            <p>Please sign in to view compliance frameworks.</p>
          ) : complianceFrameworks && complianceFrameworks.length > 0 ? (
            complianceFrameworks.map((frameworkData, index) => (
              <ComplianceTile
                key={`${frameworkData.clientName}-${frameworkData.framework}-${index}`}
                title={frameworkData.framework}
                progress={frameworkData.progress}
                total={frameworkData.total}
                backgroundColor="#E4E7DE"
                progressColor="#3E4936"
                clientNames={[frameworkData.clientName]}
                onClick={
                  frameworkData.isClickable
                    ? () => {
                        console.log('Tile clicked:', frameworkData);
                        setClientId(frameworkData.clientId);
                        setSelectedClientName(frameworkData.clientName);
                        onTileClick(frameworkData.framework, frameworkData.clientName, frameworkData.clientId);
                      }
                    : null
                }
              />
            ))
          ) : (
            <p>No compliance frameworks found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default OverviewComponent;
