import React, { useState, useEffect, useCallback } from 'react';
import { X } from 'lucide-react';
import './ScheduleDemoModal.css';
import { useNavigate } from 'react-router-dom';

const ScheduleDemoModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    jobTitle: '',
    businessEmail: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ success: false, message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ success: false, message: 'Submitting...' });

    try {
      const emailData = {
        fname: formData.firstName,
        lname: formData.lastName,
        companyName: formData.companyName,
        jobTitle: formData.jobTitle,
        businessEmail: formData.businessEmail
      };

      const response = await fetch('https://email-service-307699856776.us-central1.run.app/sendContactEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form. Please try again.');
      }

      setSubmitStatus({ 
        success: true, 
        message: 'Thank you! Our team will be in touch with you shortly.' 
      });
      
      // Navigate to thank you page after short delay
      setTimeout(() => {
        onClose();
        navigate('/thank-you/demo');
      }, 1500);

    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus({ 
        success: false, 
        message: 'Something went wrong. Please try again later.' 
      });
    }
  };

  const handleOutsideClick = useCallback((e) => {
    if (e.target.classList.contains('modal-overlay-schedule-demo')) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, handleOutsideClick]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        firstName: '',
        lastName: '',
        companyName: '',
        jobTitle: '',
        businessEmail: '',
      });
      setIsSubmitted(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-schedule-demo">
      <div className="modal-content-schedule-demo">
        <div className="modal-header">
          <div className="modal-title">
            <img src="/logo.svg" alt="Vayu Logo" className="modal-logo" />
            <h2>Schedule a demo</h2>
          </div>
          <button onClick={onClose} className="close-button">
            <X size={24} />
          </button>
        </div>
        <div className="modal-body">
          {isSubmitted ? (
            <div className={`status-message ${submitStatus.success ? 'success' : 'error'}`}>
              <h3>{submitStatus.success ? 'Thank you!' : 'Oops!'}</h3>
              <p>{submitStatus.message}</p>
            </div>
          ) : (
            <>
              <p className="modal-description">Fill in your information to book a demo with our team.</p>
              <form onSubmit={handleSubmit} className="formstyle">
                {submitStatus.message && (
                  <div className={`status-message ${submitStatus.success ? 'success' : 'error'}`}>
                    {submitStatus.message}
                  </div>
                )}
                <div className="form-row">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First name*"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last name*"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-row">
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Company Name*"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="jobTitle"
                    placeholder="Job Title*"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    required
                  />
                </div>
                <input
                  type="email"
                  name="businessEmail"
                  placeholder="Business Email*"
                  value={formData.businessEmail}
                  onChange={handleChange}
                  required
                  className="full-width"
                />
                <button type="submit" className="submit-button">
                  Submit
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleDemoModal;
