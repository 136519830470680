import React from 'react';
import { Link } from 'react-router-dom';
import './ThankYou.css';

const ThankYou = () => {
  return (
    <div className="thank-you-container">
      <h1 className="thank-you-title">Thank You!</h1>
      <p className="thank-you-message">A member of our team will be in contact with you soon.</p>
      <Link to="/" className="return-link">Return to Home</Link>
    </div>
  );
};

export default ThankYou;