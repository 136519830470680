import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import ScheduleDemoModal from './ScheduleDemoModal';

const HeroSection = () => {
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [timerKey, setTimerKey] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        nextImage();
        setTimerKey(prev => prev + 1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isPaused]);

  const images = [
    '/security-grid.png',
    '/security-grid-2.png',
    '/security-grid-3.png',
    '/security-grid-4.png',
    '/security-grid-5.png',
  ];

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const toggleScheduleModal = () => setIsScheduleModalOpen(!isScheduleModalOpen);
  const toggleImageModal = () => setIsImageModalOpen(!isImageModalOpen);

  return (
    <section className="hero">
      <div className="hero-content">
        <div className="hero-left">
          <div className="hero-title-container">
            <h1 className="hero-title">
              Meet your AI Security Workforce
            </h1>
          </div>
          <div className="hero-subtitle-container">
            <p className="hero-subtitle">
              Stay ahead of regulatory changes and accelerate compliance with Vayu's integrated intelligence platform.
            </p>
          </div>
          <button className="btn-hero" onClick={toggleScheduleModal}>
            Schedule a demo
          </button>
        </div>
        <div 
          className="hero-image" 
          onClick={toggleImageModal} 
          role="button" 
          tabIndex={0}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <button className="carousel-btn prev" onClick={(e) => { e.stopPropagation(); prevImage(); }}></button>
          <img 
            src={images[currentImageIndex]}
            alt={`Security compliance grid ${currentImageIndex + 1}`}
            className="security-grid-image"
          />
          <button className="carousel-btn next" onClick={(e) => { e.stopPropagation(); nextImage(); }}></button>
          
          <div className="timer-bar-container">
            <div 
              key={timerKey} 
              className="timer-bar"
            />
          </div>
        </div>
      </div>
      
      <ScheduleDemoModal isOpen={isScheduleModalOpen} onClose={toggleScheduleModal} />
      
      {/* Image Preview Modal */}
      {isImageModalOpen && (
        <div className="image-modal-overlay" onClick={toggleImageModal}>
          <div className="image-modal-content" onClick={e => e.stopPropagation()}>
            <button className="modal-close-btn" onClick={toggleImageModal}>×</button>
            <img 
              src={images[currentImageIndex]}
              alt={`Security compliance grid ${currentImageIndex + 1}`}
              className="modal-image"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default HeroSection;
